import React from 'react';
import {Link} from 'react-router-dom';

const Nosotros = ()=>{
    // const datos = [
    //     {id:1, nombre:'juan'},
    //     {id:1, nombre:'juan'}
    // ]

    const [equipo, setEquipo] = React.useState([])
    React.useEffect(()=>{
        obtener();
    }, []);
    
    const obtener = async () => {
        const data = await fetch('https://jsonplaceholder.typicode.com/users')
        const user = await data.json()
        // console.log(user);
        setEquipo(user);
    }

    return (
        <div>
            <h1>Nosotros</h1>
            <ul>
                {
                    equipo.map(item =>(
                        <li key={item.id} >
                            <Link to={`/nosotros/${item.id}`}>
                                {item.name} - {item.email}
                            </Link>
                        </li> 
                    ))
                    
                }
            </ul>
        </div>
    );
}

export default Nosotros;