import React from 'react';
import { useParams } from 'react-router-dom';


const User = ()=>{
    const {id} = useParams();

    const [user, setUser] = React.useState([])
    const [addre, setAddre] = React.useState([])
    const [geo, setGeo] = React.useState([])
    React.useEffect(()=>{
        obtener();
    }, []);
    
    const obtener = async () => {
        const data = await fetch(`https://jsonplaceholder.typicode.com/users/${id}`)
        const user = await data.json()
        // console.log(user);
        setUser(user);
        setAddre(user.address);
        setGeo(user.address.geo);
    }

    return (
        <div>
            <h1>{user.name}</h1>
            <ul>
                <li>Email : {user.email}</li>
                <li>Telefono : {user.phone}</li>
                <li>Direccion : {addre.street}</li>
                <li>Latitud : {geo.lat}</li>
                <li>Longitud : {geo.lng}</li>
            </ul>
        </div>
    );
}

export default User;