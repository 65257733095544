import React from 'react';

const Inicio = ()=>{
    // const datos = [
    //     {id:1, nombre:'juan'},
    //     {id:1, nombre:'juan'}
    // ]

    // const [equipo, setEquipo] = React.useState(null)
    // React.useEffect(()=>{

    // }, []);

    return (
        <div>
            <h1>Inicio</h1>
        </div>
    );
}

export default Inicio;