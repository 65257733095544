import React from 'react';

const Contacto = ()=>{
    // const datos = [
    //     {id:1, nombre:'juan'},
    //     {id:1, nombre:'juan'}
    // ]

    // const [equipo, setEquipo] = React.useState(null)
    // React.useEffect(()=>{

    // }, []);

    return (
        <div>
            <h1>Contacto</h1>
        </div>
    );
}

export default Contacto;