import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Inicio from './Components/Inicio';
import Contacto from './Components/Contacto';
import Nosotros from './Components/Nosotros';
import User from './Components/User';

function App() {
  return (
    <Router>
      <div >
        <div>
          <Link to='/' >Inicio</Link>
          <Link to='/contacto' >Contacto</Link>
          <Link to='/nosotros' >Nosotros</Link>
        </div>
        <hr />
        <Routes>
          <Route path='/' element={<Inicio />} />
          <Route path='/contacto' element ={<Contacto />} />
          <Route path='/nosotros/:id' element={<User />} />
          <Route path='/nosotros' element={<Nosotros />} />
        </Routes>
        
      </div>
      
    </Router>
  );
}

export default App;
